<template>
  <div>
    <page-container :title="breadcrumb" :breadcrumb="breadcrumb">
      <div class="bg-white">
        <div class="main-container" style="margin-top: -15px">
          <div class="title">平台功能参数</div>
        </div>
      </div>
      <div class="container">
        <div class="table-btns" style="margin-top: 2%">
          <a-button type="primary" size="large" @click="getOrSet" style="margin-left: 80%">
            {{status ?'下发当前参数':'获取最新参数' }}</a-button>
          <a-icon type="redo" class="after-btn table-refresh" @click="getParams" style="margin-left: 1%;font-size: 20px"></a-icon>
        </div>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:12}" :wrapper-col="{span:12}" style="margin-top: 1%">
          <a-form-model-item label="流媒体查询IP列表">
            <a-input v-model="platParams.stream_ip_list" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="人脸识别阈值">
            <a-input v-model="platParams.face_threshold" placeholder="" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-divider>平台自检日志参数</a-divider>
          <a-form-model-item label="平台自检日志保存时间">
            <a-input v-model="platParams.saved_time" suffix="天" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="TF卡异常发送人员手机号">
            <a-input v-model="platParams.tf_abnormal_phone" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="CPU平均使用率阈值">
            <a-input v-model="platParams.cpu_avr_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="CPU最大使用率阈值">
            <a-input v-model="platParams.cpu_max_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="CPU平均使用温度阈值">
            <a-input v-model="platParams.cpu_avr_temp" suffix="℃" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="CPU最大使用温度阈值">
            <a-input v-model="platParams.cpu_max_temp" suffix="℃" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="磁盘平均使用率阈值">
            <a-input v-model="platParams.disk_avr_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="磁盘最大使用率阈值">
            <a-input v-model="platParams.disk_max_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="IO平均使用率阈值">
            <a-input v-model="platParams.io_avr_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="IO最大使用率阈值">
            <a-input v-model="platParams.io_max_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="内存平均使用率阈值">
            <a-input v-model="platParams.memory_avr_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="内存最大使用率阈值">
            <a-input v-model="platParams.memory_max_rate" suffix="%" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="上行网络平均速度阈值">
            <a-input v-model="platParams.up_net_avr_rate" suffix="Mbps" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="上行网络最大速度阈值">
            <a-input v-model="platParams.up_net_max_rate" suffix="Mbps" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="下行网络平均速度阈值">
            <a-input v-model="platParams.down_net_avr_rate" suffix="Mbps" style="width: 300px"></a-input>
          </a-form-model-item>
          <a-form-model-item label="下行网络最大速度阈值">
            <a-input v-model="platParams.down_net_max_rate" suffix="Mbps" style="width: 300px"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </page-container>
  </div>
</template>
<script>

import {findFlatParams, modifyFlatParams} from "A/xtpz";

export default {
  name: "functionParams",
  data() {
    return {
      breadcrumb: [
        {
          name: '通用设置',
          path: ''
        },
        {
          name: '平台参数',
          path: ''
        },
        {
          name: '平台功能参数',
          path: ''
        },
      ],
      platParams:{
        paramsid:null,
        face_threshold:null,
        //平台自检日志保存时间
        saved_time:'',
        //TF卡异常发送人员手机号
        tf_abnormal_phone:'',
        //流媒体查询IP
        stream_ip_list:'',
        //CPU平均使用率阈值
        cpu_avr_rate:'',
        //CPU最大使用率阈值
        cpu_max_rate:'',
        //CPU平均使用温度阈值
        cpu_avr_temp:'',
        //CPU最大使用温度阈值
        cpu_max_temp:'',
        //磁盘平均使用率阈值
        disk_avr_rate:'',
        //磁盘最大使用率阈值
        disk_max_rate:'',
        //IO平均使用率阈值
        io_avr_rate:'',
        //IO最大使用率阈值
        io_max_rate:'',
        //内存平均使用率阈值
        memory_avr_rate:'',
        //内存最大使用率阈值
        memory_max_rate:'',
        //上行网络平均速度阈值
        up_net_avr_rate:'',
        //上行网络最大速度阈值
        up_net_max_rate:'',
        //下行网络平均速度阈值
        down_net_avr_rate:'',
        //下行网络最大速度阈值
        down_net_max_rate:'',
      },
      status:false,
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // this.getParams()
    },
    getParams(){
      let params={
      }
      findFlatParams(params).then(res=>{
        if(res&&res.returncode=="0"){
          this.platParams=res.item[0]
        }
      })
    },
    setParams(){
      let params=this.platParams
      modifyFlatParams(params).then(res=>{
        if(res&&res.returncode=="0"){
          this.$message.success("参数下发成功")
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParams();
        this.status=false;
      }else{
        this.getParams();
        this.status=true
      }
    },
  }
}
</script>
<style scoped>
.bg-white {
  background-color: #fff;
}
.container{
  background-color: #fff;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  flex-grow: 1;
  overflow: auto;
  height: 730px;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}
.main-container {
  display: flex;
  padding: 10px;
}

</style>